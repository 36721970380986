<script lang="ts">
	import { onMount } from 'svelte'
	import type { AnalyticsEvent } from '~/lib/types'
	import { track } from '~/lib/va'

	export let e: AnalyticsEvent
	export let data = null

	onMount(async () => {
		try {
			track(e, data)
		} catch (error) {
			console.error(error)
		}
	})
</script>
